const routes = [
    {
        path: '/',
        redirect: '/system/homePage'
    },
    {
        path: 'findGoods',
        name: 'findGoods',
        component: () => import('@/views/managementSystem/pages/findGoods.vue'),
        meta:{
            isShowSidebar:true,
        }
    },
    {
        path: 'homePage',
        name: 'homePage',
        component: () => import('@/views/managementSystem/pages/homePage.vue'),
        meta:{
            isShowSidebar:true,
        }
    },
    {
        path: 'shoppingCart',
        name: 'shoppingCart',
        component: () => import('@/views/managementSystem/pages/shoppingCart/index.vue'),
        meta:{
            isShowSidebar:true,
        },
        children:[
            {
                path: '/',
                redirect: '/system/shoppingCart/cartIndex'
            },
            {
                path: 'cartIndex',
                name: 'cartIndex',
                component: () => import('@/views/managementSystem/pages/shoppingCart/cartIndex.vue'),
                meta:{
                    isShowSidebar:true,
                }
            }
        ]
    },
    {
        path: 'shopOrder',
        name: 'shopOrder',
        component: () => import('@/views/managementSystem/pages/shopOrder.vue'),
        meta:{
            isShowSidebar:true,
        }
    },
    {
        path: 'suppliersInterest',
        name: 'suppliersInterest',
        component: () => import('@/views/managementSystem/pages/suppliersInterest.vue'),
        meta:{
            isShowSidebar:true,
        }
    },
    {
        path: 'purchasOrderManage',
        name: 'purchasOrderManage',
        component: () => import('@/views/managementSystem/pages/purchasOrderManage/purchasOrderManage.vue'),
        meta:{
            isShowSidebar:true,
        }
    },
    {
        path: 'information',
        name: 'information',
        component: () => import('@/views/managementSystem/pages/information.vue'),
        meta:{
            isShowSidebar:true,
        }
    },
    {
        path: 'billTermManage',
        name: 'billTermManage',
        component: () => import('@/views/managementSystem/pages/billTermManage/index.vue'),
        meta:{
            isShowSidebar:true,
        },
        children:[
            {
                path: '/',
                redirect: '/system/billTermManage/billTermManage'
            },
            {
                path: 'billTermManage',
                name: 'billTermManage',
                component: () => import('@/views/managementSystem/pages/billTermManage/billTermManage.vue'),
                meta:{
                    isShowSidebar:true,
                }
            },
            {
                path: 'repayment',
                name: 'repayment',
                component: () => import('@/views/managementSystem/pages/billTermManage/repayment.vue'),
                meta:{
                    isShowSidebar:true,
                }
            }
        ]
        
    }
]
export default routes